import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import BackgroundImage from '../components/BackgroundBottom';
import FAQ from '../components/FAQ';
import Navbar from '../components/Navbar';
import styled from 'styled-components';
import toTopArrow from '../img/to-top-arrow.svg';
import smoothscroll from 'smoothscroll-polyfill';

export class FAQsPageTemplate extends React.Component {
  constructor(props) {
    super();
    this.page = props.page;
    // this.state = {
    //   generalIndex: 0
    // };
    // this.incCounter = this.incCounter.bind(this);
  }

  // incCounter = () =>
  //   this.setState({
  //     generalIndex: this.state.generalIndex + 1,
  //   })

  scrollToId = (groupIndex, index) => {
    const elementId = document.getElementById('group' + groupIndex + 'index' + index);
    if (elementId != null) {
      elementId.scrollIntoView({ behavior: 'smooth' });
    }
  };
  componentDidMount() {
    try {
      smoothscroll.polyfill();
    } catch (e) {
      console.log(e);
    }
    this.scrollToTop = () => {
      if (typeof window !== 'undefined') {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    };
  }

  render() {
    return (
      <div
        style={{
          color: 'white',
          backgroundColor: '#110D11',
          borderBottom: '1px solid #251f26',
        }}
      >
        <section className="hero is-medium has-background">
          <img src="../img/FAQ-header-img.jpg" alt="" className="hero-background" />
          <BackgroundImage zIndex={0} />
          <div className="hero-head">
            <div className="has-text-centered is-5-to-10-margin">
              <Navbar />
            </div>
          </div>
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-offset-3 is-mobile">
                  <h1 className="is-size-1" style={{ paddingBottom: '20px' }}>
                    {this.page.frontmatter.title}
                  </h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="columns">
                <div className="column is-offset-4 is-5">
                  {this.page.frontmatter.faqGroups.map((group, groupIndex) => (
                    <div key={groupIndex} style={{ paddingBottom: '20px' }}>
                      <HeaderListGroup>
                        <div onClick={() => this.scrollToId(groupIndex, -1)}>
                          {group.groupTitle}
                        </div>
                      </HeaderListGroup>
                      <HeaderList>
                        {group.faqs.map((faq, index) => (
                          <HeaderListItem
                            key={index}
                            onClick={() => this.scrollToId(groupIndex, index)}
                          >
                            {faq.question}
                          </HeaderListItem>
                        ))}
                      </HeaderList>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container" style={{ paddingBottom: '75px' }}>
          {this.page.frontmatter.faqGroups.map((group, groupIndex) => (
            <div key={groupIndex} style={{ paddingBottom: '20px' }}>
              <GroupTitle id={'group' + groupIndex + 'index-1'}>{group.groupTitle}</GroupTitle>
              {group.faqs.map((faq, index) => (
                <section
                  key={index}
                  id={'group' + groupIndex + 'index' + index}
                  className="section"
                >
                  <FAQ title={faq.question} answers={faq.answers} index={index + 1} />
                </section>
              ))}
            </div>
          ))}
        </section>
        <section style={{ paddingBottom: '75px' }}>
          <div className="columns is-mobile">
            <div className="column is-offset-10-tablet is-1-tablet is-offset-9-mobile is-2-mobile">
              <Img
                src={toTopArrow}
                alt="Back to Top"
                title="Back to Top"
                onClick={() => this.scrollToTop()}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const FAQsPage = ({ data }) => {
  const { markdownRemark: page } = data;
  const {
    frontmatter: {
      seo: { browserTitle },
    },
  } = page;

  return (
    <Layout>
      <Helmet>
        <title>{browserTitle}</title>
      </Helmet>
      <FAQsPageTemplate page={{ ...page, bodyIsMarkdown: true }} />
    </Layout>
  );
};

FAQsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FAQsPage;

export const faqsPageQuery = graphql`
  query FAQsPage {
    markdownRemark(frontmatter: { templateKey: { eq: "faqs-page" } }) {
      frontmatter {
        title
        faqGroups {
          groupTitle
          faqs {
            question
            answers {
              paragraph
            }
          }
        }
        seo {
          browserTitle
        }
      }
    }
  }
`;

const HeaderListGroup = styled.div`
  font-weight: bold;
  font-size: 32px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const HeaderList = styled.ol`
  margin-inline-start: 1em;
  font-weight: bold;
`;

const HeaderListItem = styled.li`
  padding-bottom: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const Img = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;
const GroupTitle = styled.div`
  font-weight: bold;
  font-size: 30px;
  text-align: left;
  padding-left: 1.5rem;
  @media screen and (min-width: 769px) {
    text-align: center;
    padding-left: 0rem;
  }
`;
