import React from 'react';
import PropTypes from 'prop-types';

const FAQ = ({ title, answers, index }) => (
  <div className="container">
    <div className="columns">
      <div className="column is-4-tablet term-container">
        <div className="columns is-centered term-header-sticker">
          <div className="column is-6-tablet term-header">
            <h1 className="is-size-4" style={{ color: '#A7A6B3' }}>
              {index}.
              <br />
              {title}
            </h1>
          </div>
        </div>
      </div>
      <div className="column is-5-tablet">
        {answers.map((p, i) => (
          <div key={i}>
            {/* TODO: remove this once 100% we don't neen paragraph numbering */}
            {/* {i === 0 ? <div>{index}. {p.paragraph}</div> : <p>{p.paragraph}</p>} */}
            <p>{p.paragraph}</p>
            <br />
          </div>
        ))}
      </div>
    </div>
  </div>
);

FAQ.propTypes = {
  title: PropTypes.string
};

export default FAQ;
